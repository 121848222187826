import React, { useEffect } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/regions/header"
import HeaderSecondary from "../../components/regions/headerSecondary"
import Container from "../../components/block/container"
import FieldText from "../../components/field/fieldText"
import "../../styles/components/menu--secondary.scss"
import "../../styles/components/block--menu-secondary-trigger.scss"
import { useGlobalContext } from "../../context/context"

const CustomerSupportTeams = ({ location }) => {
  const { handleCurrentLocation } = useGlobalContext()

  useEffect(() => {
    handleCurrentLocation(location.pathname)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header>
        <HeaderSecondary />
      </Header>
      <Layout>
        <Seo
          title="Customer Support Teams"
          description=""
          pageClass="page--customer-support-teams"
        />
        <Container classes="block--page-top-content block--page-top-content--features">
          <FieldText>
            <h1 className="text text--centered text--margin-bottom">
              Customer Support Teams
            </h1>
            <h3 className="text text--centered text--red text--uppercase">
              Coming soon
            </h3>
          </FieldText>
        </Container>
      </Layout>
    </>
  )
}

export default CustomerSupportTeams
